@use '@skyscanner/backpack-web/unstable__bpk-mixins/breakpoints';
@use '@skyscanner/backpack-web/unstable__bpk-mixins/tokens';

$max-width: 1224 * tokens.$bpk-one-pixel-rem;

.Breadcrumbs {
  padding-top: tokens.bpk-spacing-base();
  padding-bottom: tokens.bpk-spacing-xxl();
  /* stylelint-disable-next-line selector-pseudo-class-no-unknown */
  :global(.main-wrapper) > :global(.feature-container) & {
    max-width: $max-width;
    margin: 0 auto;

    @include breakpoints.bpk-breakpoint-mobile {
      max-width: 100%;
      margin: 0 tokens.bpk-spacing-base();
    }

    @media screen and (max-width: $max-width) and (min-width: tokens.$bpk-breakpoint-mobile) {
      max-width: 100%;
      margin: 0 tokens.$bpk-breakpoint-small-tablet-margin;
    }
  }

  &__breadcrumbs {
    margin: 0;
    padding: 0;
    color: tokens.$bpk-core-accent-day;
  }

  &__breadcrumb {
    display: inline;
    max-width: 707;
    margin: 0;
    font-size: tokens.$bpk-font-size-sm;
    line-height: tokens.$bpk-line-height-sm;
    list-style-type: none;

    a:visited {
      color: tokens.$bpk-core-accent-day;
    }

    &:not(:first-of-type)::before {
      content: '>';
      padding: 0 tokens.bpk-spacing-sm();
    }

    &:last-of-type {
      a {
        font-weight: bold;
      }
    }
  }
}
